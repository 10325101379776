.k2-multiselector {
  margin: 0 !important;

  .ant-card-head,
  .ant-card-body {
    padding: 0 10px !important;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .k2-multiselector-header,
  .k2-multiselector-items {
    padding-right: 5px;
  }

  .k2-multiselector-body {
    padding: 10px 0;
    .k2-multiselector-items {
      min-height: 70px;
      max-height: 200px;
      overflow-y: auto;

      .k2-multiselector-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 !important;
        
        &:hover {
          cursor: pointer;
          background-color: #e6faff;
        }

        .k2-multiselector-item-check {
          width: 100%;
          padding: 8px 0;
        }

        .k2-multiselector-item-info {
          float: right;
          padding-right: 8px;
          padding-left: 8px;
        }
        .k2-multiselector-item-title {
          display: inline-block;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          line-height: 20px;
        }
      }
    }
  }
}

.has-error {
  .k2-multiselector {
    border-color: #f5222d;
  }
}

.has-warning {
  .k2-multiselector {
    border-color: #faad14;
  }
}

.k2-divider {
  width: 100%; 
  text-align: left; 
  border-bottom: 1px solid #d9d9d9 !important; 
  line-height: 0.1em;
  margin: 20px 0 20px; 
    .k2-divider-left-text { 
      background:#fff; 
      padding-right:10px; 
      font-size: 1em;
      color: #0e1963;
   }  
} 

.k2-multiselector-item-with-border {
  border-bottom: 1px solid #d9d9d9;
} 
