.k2grid {
  &.k2grid-no-min-height {
    .ant-table-body {
      min-height: unset;
    }
  }

  table {
    td:first-child {
      padding-left: 10px;
    }
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      top: 7px;
      left: 7px;
    }
  }

  .k2-next-prev-pager {
    .ant-pagination-item,
    .ant-pagination-jump-next {
      display: none;
    }
  }

  .ant-table-title:empty + .ant-table-content {
    border-radius: inherit;
  }
}

