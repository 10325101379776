@import "../../styles/variables.less";

//edit subs menu style here

.app-list div {
  margin: 24px;
}

.subs-menu-item {
  padding: 0 !important;

  &:hover {
    background-color: initial !important;
    cursor: default !important;
  }
}

.@{class-prefix}-subs-menu {
  min-width: 520px;
  min-height: 260px;
  border-radius: 8px;

  @media screen and (max-width: 575px) {
    min-width: 97vw;
  }

  a {
    font-size: @h5-font-size;
    font-weight: @font-weight-semi-bold;
    color: @grey-8;

    &:hover {
      color: @primary-7;
    }

    &:focus {
      color: @primary-7;
    }
  }

  .manage-subs {
    position: absolute;
    bottom: 24px;
    font-size: small;
    font-weight: @font-weight-semi-bold;
    color: @grey-9;
    border-top: 1px @grey-4 solid;
    padding-top: 10px;

    &:hover {
      color: @primary-7;
    }

    &:focus {
      color: @primary-7;
    }
  }

  .release-notes {
    padding: 24px 24px !important;
    margin: -4px 0 !important;
    border-radius: 0 7.2px 8.22px 0;

    .notes {
      strong {
        font-weight: 600 !important;
      }
    }

    & .ant-typography {
      color: @grey-2;
    }

    & p {
      font-size: small;
      color: @grey-5;
    }

    .release-note-custom-scrollbar {
      margin: 0;

      > div {
        margin-left: 0;
        margin-top: 0;
      }

      // .render-view {
      //   margin-right: -10% !important;
      //   margin-bottom: -10% !important;
      // }

      //// TODO: might need this alter
      // .notes {
      //   padding-left: 10px;
      // }
    }
  }

  .subs-custom-scrollbar {
    margin: 0;

    > div {
      margin-left: 0;
      margin-top: 0;
    }

    .first-sub {
      margin-top: 0;
    }

    .render-view {
      margin-right: -10% !important;
      margin-bottom: -10% !important;
    }
  }

  .loading-skeleton {
    width: 75%;
  }
}

